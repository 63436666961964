import { Component, createRef } from 'react'

import ReactDOM from 'react-dom'
import { generateEmptyResponse } from '../../../helpers/sparql'
import './BBIFrame.css'

import throttle from 'lodash/throttle'
import PublishUtils from '../../../helpers/PublishUtils'
import AfterJS from '../../../helpers/AfterJS'
import { parseTextForVariables } from '../../../helpers/sparql'
import './bbutil'
import {getSparqlFunction} from '../../../helpers/utils'

import Command from '../../../helpers/Command';
import { getAbsoluteRepoUrl } from '../../../helpers/url'

import shallowCompare from 'react-addons-shallow-compare'; 

import _ from 'lodash';
import { setupUserInputMessenger } from '../../../helpers/user-input-messenger'
import { toast } from 'react-toastify'

class BBIFrame extends Component {
  iframeRef = createRef()

  state = {
    imgViz: false,
    selectedUri: null,
    reloadID: 1,
  }

  static htmln = 0
  static scrolling = 'no'
  noEvents = false;
  usesParameters=true;

   // handy for Iface js to use
   getAbsoluteRepoUrl=getAbsoluteRepoUrl;  
   parseTextForVariables=parseTextForVariables;

  command = Command;
  commandInstance = new Command(this);

  publishUtils = PublishUtils;

 showToast(msg,severity) {
    if (severity==null) severity="info";
    toast(msg, { type: severity })

 }

  uploadSuccessfulAfter() {
    //console.log("after upload");
    PublishUtils.setTimeStampParameter(this, true)
  }
  

   executeSparql(sparql,rv){

    
     let qq =parseTextForVariables(sparql, this.props);
     
      if (this.query==null)
      {
        this.query=getSparqlFunction(this.props);
      }



    this.query(qq,function(arg){
       
       if (rv!=null) rv.call(this,arg);}
       ,function(arg2){
        console.log(arg2);});
     

   }

  onClick = (event, uri) => {
   
    var me = this
    if (uri == null) {
      uri = event
    }
    if (
      event != null &&
      event === 'http://www.bbsgroep.nl/application/v2/hasTextOutput'
    ) {
      me.noEvents = true
      var pvar = me.props.publishTextVariable
      if (pvar != null) {
        me.props.publish(pvar, uri)
        //PublishUtils.resetParameter(me);
      }
      me.noEvents = false

      return
    }
    if (
      event != null &&
      event === 'http://www.bbsgroep.nl/application/v2/hasVar1Output'
    ) {
      me.noEvents = true
      pvar = me.props.publishVariable1
      if (pvar != null) {
        if (uri !== event) {
          me.props.publish(pvar, uri)
        }
        //PublishUtils.resetParameter(me);
      }
      me.noEvents = false

      return
    }

    //console.log("continuing");
    me.noEvents = true
    var pvar = me.props.publishVariable
    //console.log(pvar);
    if (pvar != null) {
      // console.log("event iframe, setting variable ",pvar,uri,me)
      me.props.publish(pvar, uri)
      //  console.log("run after selection  ",AfterJS);
      try {
        AfterJS.runAfterSelection(me)
      } catch (e) {
        console.log(e)
      }
      // console.log("run after selection ended");
      PublishUtils.resetParameter(me)
    }
    me.noEvents = false
  }

  getUrl() {
    return ''
  }

  componentDidMount() {
    var me = this
    this.mounted = true
    // var iframe=ReactDOM.findDOMNode(this);
    var div = ReactDOM.findDOMNode(this).parentNode

    div.setAttribute('style', 'position:relative;')

    if (this.iface != null) {
      try {
        if (me.iface.afterIntegration != null) {
          me.iface.afterIntegration(this.props)
        }
      } catch (e) {
        console.log(e)
      }
      this.updateMe(null)
    }
  }

   getObjectDiff(obj1, obj2) {
    const diff = Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
            result.push(key);
        } else if (_.isEqual(obj1[key], obj2[key])) {
            const resultKeyIndex = result.indexOf(key);
            result.splice(resultKeyIndex, 1);
        }
        return result;
    }, Object.keys(obj2));

    return diff;
}


  shouldComponentUpdate(arg, arg2) {
   // console.log(this.getUrl()+" should update ",this.mounted,this.iface,this.noEvents );
    if (!this.mounted) {return false;}
    if (this.iface == null) {return false;}
    if (this.noEvents) {return false;}
     var pubsubNew= _.cloneDeep(arg.pubsub);
     var pubsubOrg= _.cloneDeep(this.props.pubsub);
     delete pubsubOrg.update60;
     delete pubsubOrg.update60Timestamp;
     delete pubsubOrg.update60Running;
     delete pubsubOrg.update60RunningTimestamp;
    
     delete pubsubNew.update60;
     delete pubsubNew.update60Timestamp;
     delete pubsubNew.update60Running;
     delete pubsubNew.update60RunningTimestamp;
    
     
     
   // if (shallowCompare(this, arg, arg2)==false && _.isEqual(arg.pubsub, this.props.pubsub))
   if (shallowCompare(this, arg, arg2)==false && _.isEqual(pubsubNew, pubsubOrg))
  // if (_.isEqual(this.state, arg2) && _.isEqual(pubsubNew, pubsubOrg))
    {
    //  console.log("shallowcompare says false for update for ",this.getUrl());
       return false;
      }

      //console.log(arg.pubsub,this.props.pubsub)
    //console.log(this.getUrl()+" should update",_.isEqual(this.state,arg2),this.getObjectDiff(this.state, arg2),this.getObjectDiff(pubsubNew, pubsubOrg));
    return true
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateMe(this.props, this.state)

    if (this.iface.onComponentDidUpdate) {
      this.iface.onComponentDidUpdate(prevProps, prevState)
    }
  }

  registerMe() {
   // console.log("IFRAME register me "+this.getUrl());

    var me = this

    setupUserInputMessenger(me.iframeRef.current.contentWindow)

    me.iface.me = me
    // console.log("add set loading to iface");
    me.iface.setLoading = function (arg) {
      me.props.setLoading(arg)
    }
    me.iface.setMessage=function(msg)
    {
     //hier norents toaster functie
     if (msg==null)return;
     try
     {
        me.props.sendNotification(msg)
     }
     catch(e){}
     console.log("Toaster msg:",msg)


    }

    if (me.iface.widget == null) {
      me.iface.widget = {
        sendEvent: function (e, arg) {
          var pvar = me.props.publishVariable
          if (pvar != null) {
            //  console.log("running iframe onclick event ",e,arg);
            me.onClick(arg)
            me.setState({ selectedUri: arg })
          }
        },
        publish: this.publish,
        publishList: this.publishList,
        test: function () {
          console.log('test')
        },
        reExecuteSparql: function () {
          try {
            me.reExecuteSparql()
          } catch (e) {
            console.log(e)
          }
        },
      }
    }

    if (this.mounted) {
      try {
        if (me.iface.afterIntegration != null) {
          //  console.log("after integration called");
          me.iface.afterIntegration()
        }
      } catch (e) {
        console.log(e)
      }
      //console.log("registerMe this.updateMe"+this.getUrl());
      this.updateMe(this.props)
    }
  }

  publish = (publishVariableName, value) => {
    
    const publishVariable = this.props[publishVariableName]
    if (!publishVariable) return
  //console.log(this.getUrl(),publishVariable,value);
    this.props.publish(publishVariable, value)
  }

  publishList = (publishVariableName, publishVariableParameterTypeName, list) => {
    const publishVariable = this.props[publishVariableName]
    if (!publishVariable) return
   // console.log(this.getUrl(),publishVariableName,publishVariableParameterTypeName,list);
    const publishVariableParameterType = this.props[publishVariableParameterTypeName]
    const singleValue = PublishUtils.getListAsSingleValue(publishVariableParameterType, list)
    this.props.publish(publishVariable, singleValue)
  }

  //updateMe = throttle((arg,arg2) => { this.updateMe2(arg,arg2)}, 200)
  updateMe =this.updateMe2;
  updateMe2(arg={} , arg2={}) {
   
    var me = this;
    var data = arg.data
   
    
       
    try {
      if (me.props.data == null && data == null && me.firstData22 != null) {
     //  console.log("setbegin sparql results   1");
        var q = me.props.query
        if (me.props.basequery) q = me.props.basequery
        if (arg.executedSparql != null) q = arg.executedSparql
        let qq = null
        if (q != null) {
          qq = parseTextForVariables(q, arg)
        }

        me.iface.setBeginSparqlResults(me.firstData22, q, null, null, qq)
        delete me.firstData22
      } else {
    //   console.log(arg.data,me.latestData22);
        if (arg.data != null) {
          if (me.latestData22 != arg.data)
           {
            let q = arg.query
            if (arg.executedSparql != null) q = arg.executedSparql

            let qq = null
            if (q != null) {
              qq = parseTextForVariables(q, arg)
            }
           // console.log(this.getUrl(),"BB IFRAME SET BEGIN SPARQL results  2",this.props.pubsub,arg.pubsub);
            me.iface.setBeginSparqlResults(arg.data, q, null, null, qq)
            me.latestData22 = arg.data
          }
          else {
           // console.log(" not updating latest data22 == arg.data");
            
          }
        } else {
         //  console.log(me.props.data,me.latestData22 !=me.props.data)
          if (me.props.data != null) {
           
            if (me.latestData22 != me.props.data) {
             
              let q = me.props.query
              if (arg.executedSparql != null) q = arg.executedSparql

              let qq = null
              if (q != null) {
                qq = parseTextForVariables(q, arg)
              }
                // console.log("set sparql");
              me.iface.setBeginSparqlResults(me.props.data, q, null, null, qq)
              me.latestData22 = me.props.data
            }
          } else {
           // console.log(this.props.basequery);
            if (me.props.basequery != null) {
              me = this
              if (me.onlyOnce == null) {
                me.onlyOnce = 'done'
                if (me.props.basequery.length > 10) {
                //     console.log("iframe geen data maar wel een base query",this.props.basequery);
                  window.top.query(me.props.basequery, function (data) {
                    me.firstData22 = data
                    me.updateMe(null)
                  })
                }
              }
            }
          }
        }
      }
    } catch (e) {
      console.log(e)
    }

      
    //console.log("me iface setPubSub ",this.iface,this.props.pubsub);
    //console.log(arg);
    try {
      this.iface.setPubSub(arg.pubsub)
    } catch (e) {}
    if (arg == {}) {
      arg = this.props
    }
    //console.log("updateMe  "+this.getUrl());
   
    this.setSelection(arg)
  }

  setSelection(arg) {
   // console.log("iframe setSelection ",arg,this.getUrl());
    try {
      var pvar = arg.subscribeVariable
      if (pvar != null && arg.pubsub != null) {
        var selectionValue = arg.pubsub[pvar]

        if (
          selectionValue != null &&
          this.state != null &&
          this.state.selection !== selectionValue
        ) {
          if (this.iface != null) {
            // console.log("set Selection iface ",selectionValue);
            this.iface.selectUri(selectionValue)
            this.state.selection = selectionValue //eslint-disable-line react/no-direct-mutation-state
            // this.iface.setBeginSparqlResults(me.props.data,me.props.query);
          } else {
            // console.log("iframe set selection no iface");
          }
        }
      }
    } catch (e) {
      console.log('error met selection ', e)
    }
  }

  reload() {
   
    this.once = null
    this.iface = null

    this.onlyOnce = null
    window.top.Singleton.getInstance().iframes[this.htmlid] = null
    this.htmlid = null
   // console.log("reload iframe ",this.getUrl());
    this.setState({ reloadID: Date.now() })
  }

getParameters()
{

   if (!this.usesParameters)
   {
  //  console.log("NOT USING parameters....",this.getUrl());
    return "?usepar=false";

   } 
    var par = '?y=y'+Date.now();
   var title = ''
   if (this.props.title) {
     title = this.props.title
     par += '&title=' + title
   }
   var createRoot = ''
   if (this.props.createRoot) {
     createRoot = this.props.createRoot
     par += '&createRoot=' + createRoot
   }
   var icon = ''
   if (this.props.icon) {
     icon = this.props.icon
     par += '&icon=' + icon
   }

   var expandLevel = ''
   if (this.props.expandLevel) {
     expandLevel = this.props.expandLevel
     par += '&expandLevel=' + expandLevel
   }
   var direction = ''
   if (this.props.direction) {
     direction = this.props.direction
     par += '&direction=' + direction
   }

   //onderstaande code moeten we eigenlijk vanaf. IFrame objecten kunnen nu via IFace toegang krijgen
   par = '';
   let extra = '?'
   
   for (let key in this.props) {
     if (key === 'loading') continue
     if (key === 'save') continue
     if (key === 'query') continue
     if (key === 'definition') continue
     if (key === 'children') continue
     if (key === 'pubsub') continue
     if (key === 'basequery') continue
     if (key === 'repoURL') continue
     if (key === 'localRepoURL') continue
     if (key === 'sparqlErrorMessage') continue
     if (key === 'titleMenubar') continue
     if (key === 'widget') continue
     if (key === 'panelstyle') continue
     if (key === 'panelstyle2') continue
     if (key === 'extendquery') continue
     if (key === 'jsAfterSelection') continue
     if (key === 'parameterValues') continue
     if (key === 'data') continue
     if (key === 'withinSelectedTab') continue
     if (key === 'selectguidquery') continue
     if (key === 'replaceHeaderJSON') continue
     if (key=="voteForUserActionsDisabledWhenLoading") continue;
     if (key=="syncWithUserActionsDisabled") continue;
     if (key=="childParameterText") continue;
     if (key=="message") continue;
     if (key=="userActionsDisabled") continue;
     if (key=="layoutName") continue;
     if (key=="infoMenubar") continue;
     if (key=="panelstyleExtra") continue;


     let value = this.props[key]

     if (value != null) {
       if (typeof value === 'function') continue
       if (Array.isArray(value)) {
         continue //value=JSON.stringify(value); console.log("submitting array key  to iframe",key);
       }

       try {
         if (value.toString().includes('||')) {
           value = value.toString().replaceAll('||', '@@')
         }
       } catch (e) {}
       try {
         value = value.toString().replaceAll('{{', '!@!@')
       } catch (e) {}
       try {
         value = value.toString().replaceAll('}}', '@!@!')
       } catch (e) {}
       par += extra + key + '=' + value
       extra = '&'
     }
   }

   return par;

}



  render() {
   
    if (this.once == null) {
     // if (this.props.withinSelectedTab==false) return null;
      this.renderOnce();
      this.once = 'done'
    }
    var height = '100%'

    try {
      if (this.props.height != null) {
        height = this.props.height
      }
    } catch (e) {}

    var reload = 'reload=' + this.state.reloadID // used to reload the whole iframe

    var className = ''
    if (this.props.className) {
      className = this.props.className
    }
    var par =this.getParameters(); //slowly deprecating this

  //  console.log(par);
   //reload="reload=stable";
    let loading = 'lazy'
    if (par === '') reload = '?' + reload
    else reload = '&' + reload
   // console.log(this.htmlid+"",process.env.PUBLIC_URL +this.getUrl()+par+reload);
    //
    //  console.log(this.state);
    return (
      <iframe //eslint-disable-line jsx-a11y/iframe-has-title
        id={this.htmlid}
        ref={this.iframeRef}
        loading222={loading}
        className={className}
        width="100%"
        height={height}
        frameBorder="0"
       // referrerPolicy="same-origin" 
        scrolling={this.scrolling}
        src={process.env.PUBLIC_URL + this.getUrl() + par+reload }
      />
    )
  }
  renderOnce() {
    var me = this
    this.getUrl() // setting BBIFrame...
  //  console.log('render once ' + this.getUrl())
    try {
      if (!this.props.data || this.props.data.results.bindings.length === 0) {
        if (this.props.renderEmpty) {
          //  console.log("IFRAME GENERATING EMPTY RESPONSE");
          this.firstData22 = generateEmptyResponse(this.props.query)
        }
      }
    } catch (error) {}

    if (this.htmlid == null) {
      BBIFrame.htmln++
      this.htmlid = 'iframe' + BBIFrame.htmln
      //  console.log(this.htmlid,this);
    }
    if (window.top.Singleton.getInstance().iframes == null) {
      window.top.Singleton.getInstance().iframes = {}
    }

    window.top.Singleton.getInstance().iframes[this.htmlid] = function (arg) {
      me.iface = arg
      me.registerMe()
    }
  }
}
export default BBIFrame
