import React from 'react'
import styles from './Table.module.css'
import {
  restructureResponse,
  generateEmptyResponse,
  aggregateResultsByFirstParameter,
} from '../../helpers/sparql'
import PublishUtils from './../../helpers/PublishUtils'
import { Icon } from './Icon'
import Command from '../../helpers/Command'
import { Link, Tooltip } from '@mui/material'
import { runStartRule } from "../../helpers/run-start-rule"

// import { Table as StrapTable } from 'reactstrap';



function camelCaseToSpaces(str) {


  str = str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ').toLowerCase()
  str=str.replace("_"," ");
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const check = <Icon icon="check" color="green" />
const cross = <Icon icon="times" color="red" />
const crossDel = <Icon className={styles.deleteIcon +  " " + styles.link} icon="times" color="grey" />



class Table extends React.Component {

  getHeader(s)
  {
    try
    { 
      var json=JSON.parse(this.props.replaceHeaderJSON);
        var rs =json[s];
        if (rs!=null) return rs;
    }
    catch(e)
    {}
    return camelCaseToSpaces(s);
  }

  deleteClick = async (item) => {
    const rule = this.props.deletebuttonrule
    if (rule == null) {
      window.alert("This function is not available")
      return
    }

    if (!window.confirm("Are you sure you want to delete this?")) {
      return
    }

    try {
      this.props.setLoading(true)
      await runStartRule(rule, JSON.stringify({
        ...item,
        ...(this.props.deletebuttonruleParams ?? {})
      }))

      PublishUtils.setTimeStampParameter(this, false)
    } catch (e) {
      window.alert("An error occurred during deletion")
      console.error(e)
    } finally {
      this.props.setLoading(false)
    }
  }
shouldComponentUpdate(props, state,x)
{
  //var r=super.shouldComponentUpdate(props,state,x);
  if (props.data==this.props.data) 
  {
    if (this.props.highlightVariable== null)     return false;
    var par =this.props.highlightVariable;
    let selectedUri = this.props.pubsub[par];
    let selectedUri2 = props.pubsub[par];
    if (selectedUri==selectedUri2) return false;

  }
 
  if (this.props.resetSelectedValueWhenNotAvailable)
  {
    //not implemented. is seleteduri available?
   
    if (this.props.publishVariable != null) {
      let parameter = this.props.publishVariable
      let selectedUri = this.props.pubsub[parameter]
      if (selectedUri!=null)
      {
        // is reset? 
        if (selectedUri!=PublishUtils.resetUri)
        {
        // console.log("should reset value",selectedUri,props);
          this.props.publish(parameter,PublishUtils.resetUri);
          return false;
          
      
        }
      }
    }
  }
      
  
  return true;
}

  onClick = async uri => {

    if (uri==null) return;
    if (uri.length<2) return;

    if ((uri!=null) && (uri=="http://www.example.org/5c3c96ea1d83d8396b87bc0b") ) return;
   

    await PublishUtils.resetParameter(this)
    this.props.publish(this.props.publishVariable, uri)
  }
  openLink = (url) => {

  
    if (url==null) return;
    if (url.length<2) return;
    window.open(url, '_blank')
  }

  
  

  render() {
   
    var data = this.props.data;
    //if (data ==null) console.log("Table renderer heeft geen data",this.props.pubsub.datasets);
    if ( this.props.data==null || this.props.data.results==null || this.props.data.results.bindings==null ||this.props.data.results.bindings.length == 0) {
      if (this.props.renderEmpty) {
        data = generateEmptyResponse(this.props.query)
        // console.log("table rende empty ",this.props.query, this.props);
      } else {
        try {
          if (this.props.zeroResult != null) {
            if (
              this.props.zeroResult != null &&
              this.props.data.results &&
              this.props.data.results.bindings.length === 0
            ) {
              var label = this.props.zeroResult
              return (
                <div className={styles.main}>
                  <p>{label}</p>
                </div>
              )
            }
          }
        } catch (e) {}
        
        return null
      }
    }
   // console.log("rendering table not empty");

    let selectedUri = null

    if (this.props.highlightVariable != null) {
      let parameter = this.props.highlightVariable
      selectedUri = this.props.pubsub[parameter]
    }
   
    let headVars = data.head.vars
    let items = restructureResponse(data)

    if (this.props.aggregateResultsByID) {
      items = aggregateResultsByFirstParameter(items, headVars[0])
    }

    const columnNames = headVars.filter((columnName) => {
      if (columnName.endsWith('URI')) return false
      if (columnName.endsWith('URL')) return false
      if (columnName.endsWith('Icon')) return false
      if (columnName.endsWith('IconSize')) return false
      if (columnName.endsWith('IconColor')) return false
      if (columnName.endsWith('HIDDEN')) return false
      return true
    })

    const center = this.props.centerContent ? styles.centertext : ''
    const hasDeleteColumn = Boolean(this.props.deletebuttonrule)
    return (
      <div className={styles.main}>
        {this.props.title && (
          <div className={styles.title}>{this.props.title}</div>
        )}
        <table>
          {!this.props.hideHeader && (
            <thead>
              <tr>
                {this.props.hasNumberedRows && (
                  <th className={styles.rowNumberHeader +"  "+center} key={-1}></th>
                )}
                {columnNames.map((columnName, index) => ( 
                  <th className={center} key={index}>{this.getHeader(columnName)}</th>
                ))}
                  {hasDeleteColumn && (
                  <th className={styles.rowNumberHeader +"  "+center} key={-2}>delete</th>
                )}
              </tr>
            </thead>
          )}
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>

                {this.props.hasNumberedRows && (
                  <td className={styles.rowNumber+" "+center}>{index + 1}</td>
                )}

                {columnNames.map((columnName, index) => {
                  const value = item[columnName] || ''
                  const uri = item[columnName + 'URI']
                  const url = item[columnName + 'URL']
                  const icon = item[columnName + 'Icon']
                  const iconSize = item[columnName + 'IconSize']
                  const iconColor = item[columnName + 'IconColor']

                  const content = (() => {
                    if (icon) return (
                      <Tooltip title={value}>
                        <Icon icon={icon} style={{height: iconSize, color: iconColor}} alt={value} />
                      </Tooltip>
                    )
                    if (value === 'true') return check
                    if (value === 'false') return cross
                    return value
                  })()

                  return (
                    <td key={index} className={center}>
                      {(uri || url)
                        ? <Link
                            component="button"
                            className={(()=> {
                              if (uri) return `${styles.link} ${styles.inlineblock} ${uri === selectedUri ? styles.highlight : ''}`
                              if (url) return `${styles.link} ${styles.inlineblock}`
                              throw new Error('this should be unreachable')
                            })()}
                            onClick={() => {
                              if (uri) return this.onClick(uri)
                              if (url) return this.openLink(url)
                              throw new Error('this should be unreachable')
                            }}
                            dangerouslySetInnerHTML={this.props.ashtml ? {__html: content} : undefined}
                          >
                            {this.props.ashtml ? undefined : content}
                          </Link>
                        : this.props.ashtml
                          ? <div dangerouslySetInnerHTML={{__html: content}} />
                          : content
                      }
                    </td>
                  )
                })}

                {hasDeleteColumn && (
                  <td onClick={() => this.deleteClick(item)} key={index} className={styles.iconcenter+" "+center}>
                    {crossDel}
                  </td>
                )}

              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default Table
